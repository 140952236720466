import { render, staticRenderFns } from "./AnuncioForm.vue?vue&type=template&id=1ed60372&"
import script from "./AnuncioForm.js?vue&type=script&lang=js&"
export * from "./AnuncioForm.js?vue&type=script&lang=js&"
import style0 from "./AnuncioForm.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QPage from 'quasar/src/components/page/QPage.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import qInstall from "../../../../node_modules/@quasar/app/lib/webpack/runtime.auto-import.js";
qInstall(component, 'components', {QPage,QIcon});
