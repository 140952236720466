import VeiculoForm from 'src/pages/Admin/Veiculo/VeiculoForm.vue'
import AnuncioModel from 'src/model/veiculo/AnuncioModel'
import DpFormMixin from 'src/core/components/DpForm/DpFormMixin.js'
import DpForm from 'src/core/components/DpForm/DpForm2.vue'
import NotificacaoMixin from 'src/core/mixin/NotificacaoMixin'
import vTopFilters from 'src/core/filters/vTopFilters'
import { clone } from 'quasar'

export default {
  name: 'AnuncioForm',
  mixins: [DpFormMixin, NotificacaoMixin, vTopFilters],
  components: { VeiculoForm, DpForm },
  data () {
    return {
      modelPadrao: new AnuncioModel(),
      modelTipoNegociacao: new AnuncioModel(true),
      tipoNegociacao: null,
      veiculoId: null,
      situacaoId: 1,
      salvandoAnuncio: false,
      tiposNegociacao: {}
    }
  },
  meta () {
    return {
      title: 'Anúncio Form',
      meta: [
        { 'name': 'robots', 'content': 'noindex' }
      ]
    }
  },
  methods: {
    receberVeiculoId () {
      const form = this.modelPadrao.form
      if (form.bem_id) {
        this.veiculoId = form.bem_id
      }
    },
    salvarTodosTiposNegociacao () {
      const tipos = Object.keys(this.tiposNegociacao)
      tipos.forEach((tipo) => {
        // if ((tipo === 'pcd' || tipo === 'pj') ? this.modelPadrao.form.zero_km === 1 : true) {
        this.salvarTipoNegociacao({ tipo })
        // }
      })
    },
    salvarTipoNegociacao (e) {
      let tipoNegociacaoId = null
      switch (e.tipo) {
        case 'pf':
          tipoNegociacaoId = 2
          break
        case 'pj':
          tipoNegociacaoId = 1
          break
        case 'pcd':
          tipoNegociacaoId = 3
          break
        default:
          tipoNegociacaoId = 2
          break
      }
      const tipoAcao = this.tiposNegociacao[e.tipo].form.habilitar

      if (tipoAcao) {
        const form = { ...this.tiposNegociacao[e.tipo].form, anuncio_id: this.$route.params.id, tipo_negociacao_id: tipoNegociacaoId, valor_inicial_publico: 0 }
        this.tiposNegociacao[e.tipo].form.salvando = true
        const enviando = this.notificacao('enviando', `Salvando o valor para o tipo de negociação ${e.tipo}`, 5000)
        this.modelTipoNegociacao.salvar(form).then((response) => {
          enviando()
          this.tiposNegociacao[e.tipo].form = Object.assign({}, { ...this.tiposNegociacao[e.tipo].form, id: response.dados.id })
          this.tiposNegociacao[e.tipo].form.salvando = false
          this.notificacao('salvo', 'Tipo de negociação salvo com sucesso !', 2000)
        }).catch((error) => {
          this.modelTipoNegociacao.salvando = false
          this.notificacao('erro', error.msg)
        })
      } else {
        this.tiposNegociacao[e.tipo].form.salvando = true
        this.modelTipoNegociacao.deletar(this.tiposNegociacao[e.tipo].form.id).then(() => {
          let form = Object.assign({}, this.tiposNegociacao[e.tipo].form)
          Object.keys(form).forEach((chave) => {
            form[chave] = null
          })
          form['habilitar'] = false
          form['valor_inicial_publico'] = ''
          this.tiposNegociacao[e.tipo].form = Object.assign({}, form)
          this.tiposNegociacao[e.tipo].form.salvando = false
          this.notificacao('salvo', 'Tipo de notificação desabilitado com sucesso', 2000)
        }).catch(() => {
          this.tiposNegociacao[e.tipo].form.salvando = false
        })
      }
    },
    salvarVeiculo () {
      const ref = this.$refs.formVeiculo.$refs.formPadrao
      if (ref) {
        ref.enviarFormularioInterno()
      }
    },
    salvarAnuncio (e) {
      console.log('marcelo', e)
      const dadosVeiculo = e.dados
      const { anuncioNome } = e
      this.salvandoAnuncio = true
      const form = {
        nome: anuncioNome,
        bem_id: dadosVeiculo.id,
        data_deletado_alpes: 'null',
        pronta_entrega: e.pronta_entrega,
        outlet: e.outlet,
        ipva_pago: e.ipva_pago,
        aplicativo: e.aplicativo,
        blackfriday: e.blackfriday,
        cyberweek: e.cyberweek,
        situacao_id: 1
      }
      this.modelPadrao.salvar(form).then((response) => {
        this.salvandoAnuncio = false
        const id = response.dados.id
        this.veiculoId = dadosVeiculo.id
        this.$router.push({ name: 'anuncios.form', params: { id } })
        // this.$router.replace({ name: 'anuncios.form', params: { id } })
        // window.location.reload()
      })
    },
    excluirReferenciaAlpes () {
      this.salvarAnuncio = true
      const form = { data_deletado_alpes: vTopFilters.filters.dateTimeEng(new Date()), alpes_veiculo_id: 'null', id: this.modelPadrao.form.id }
      // const form = { data_deletado_alpes: 'null', alpes_veiculo_id: '36429', id: this.modelPadrao.form.id }
      this.modelPadrao.salvar(form).then((response) => {
        this.salvandoAnuncio = false
        this.notificacao('salvo', 'A referência de integração com a ALPES foi desfeita. e os dados no INTEGRACAR não estão mais disponíveis')
      }).catch(() => {
        this.salvarAnuncio = false
        this.notificacao('erro', 'Não foi possível remover a referência de integração com a ALPES.')
      })
    },
    salvarSituacao (e) {
      console.log('[LOG][SITUACAO] salvando situacao', e)
      const form = {
        id: clone(this.modelPadrao.form.id),
        situacao_id: e.dados.situacao_id,
        pronta_entrega: e.dados.pronta_entrega,
        outlet: e.dados.outlet,
        ipva_pago: e.dados.ipva_pago,
        aplicativo: e.dados.aplicativo,
        blackfriday: e.dados.blackfriday,
        cyberweek: e.dados.cyberweek,
        data_deletado_alpes: 'null'
      }
      this.modelPadrao.salvar(form).then((response) => {
        this.salvandoAnuncio = false
        // const id = response.dados.id
        // this.$router.push({ name: 'anuncios.form', params: { id } })
      })
    },
    inicio () {
      this.modelTipoNegociacao.getListagem({ params: { search: `anuncio_id:${this.$route.params.id}` } }).then((response) => {
        if (response.dados) {
          let maps = {}
          maps[1] = 'pj'
          maps[2] = 'pf'
          maps[3] = 'pcd'
          response.dados.map((tipo) => {
            this.tiposNegociacao[maps[tipo.tipo_negociacao_id]].form = Object.assign({}, { ...tipo, habilitar: true, salvando: false })
          })
        }
      })
      if (this.modelPadrao.form.bem_id) {
        this.veiculoId = this.modelPadrao.form.bem_id
      }
      if (this.modelPadrao.form.situacao_id) {
        this.situacaoId = this.modelPadrao.form.situacao_id
      }
    }
  },
  watch: {
    '$route': {
      handler: function (agora, depois) {
        // '$route.params.id'
        console.log('m agora', agora)
        console.log('m depois', depois)
        this.inicio()
      },
      deep: false,
      immediate: false
    }
  },
  mounted () {
    if (this.$route.params.id && this.$route.params.id > 0) {
      this.modelPadrao.getId({ id: this.$route.params.id }).then((response) => {
        console.log('response', response)
      })
    }
    this.inicio()
  },
  created () {
    this.tiposNegociacao = ['pf', 'pj', 'pcd'].reduce((acc, atual) => {
      acc[atual] = new AnuncioModel(true)
      return acc
    }, {})
    Object.keys(this.tiposNegociacao).forEach((tipo) => {
      this.tiposNegociacao[tipo].form = { ...this.tiposNegociacao[tipo].form, habilitar: false, salvando: false }
    })
  }
}
